.wrapper{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.wrapper .header{
    padding: 0 .8rem;
    font-size: 3rem;
    line-height: 75px;
    margin: 0;
}
.devicesContainer{
    flex-grow: 1;
    overflow: auto;
}
.loadingContainer{
    height: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
}
.loading h3{
    margin-bottom: 20px;
}
.noLockersFound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px .8rem;
}
.noLockersFound .addDevice{
    font-size: 3rem;
    color: #00B2FF;
}

.devicesContainer div[class="devices-swipe"]>div>div{
    scroll-behavior: smooth;
}
.devicesContainer div[class~="ant-carousel"],
.devicesContainer div[class~="slick-slider"],
.devicesContainer div[class~="slick-list"],
.devicesContainer div[class~="slick-track"],
.devicesContainer div[class~="slick-slide"] > div{
    height: 100%;
}