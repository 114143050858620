/* html, body, div#root{
  min-height: 100%;
  height: 100%;
} */

html,
body,
#root,
.nav-container {
    height: 100% !important;
}

#root {
    display: flex;
    flex-direction: column;
}

.nav-container {
    flex-grow: 1;
}

p,
span,
a,
label,
h1,
h2,
h3,
h4,
h5,
h6,
img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
}
html {
    line-height: inherit !important;
}

html body {
    background-color: #ddd;
}

#root {
    box-shadow: 0 0 5px 5px #ccc;
    max-width: 500px !important;
    margin: auto;
    background: white;
    position: relative;
    overflow: hidden;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    pointer-events: none;
}

.nav-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.nav-container .content {
    flex-grow: 1;
    overflow: auto;
    position: relative;
    width: 100% !important;
    overflow: hidden !important;
}

div[class*="_wrapper"] {
    padding: .8rem;
    width: 100%;
}

body div.ant-typography {
    margin-bottom: 0;
}


p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

/* input,  
input.ant-input, 
input.ant-select-selection-search-input,
select, 
select.ant-input,
textarea, 
textarea.ant-input, 
button.ant-btn,
label, 
div.ant-form-item-label 
a, span, p {
    font-size: 1em;
} */

.qr-code {
    margin: 30px auto 0 auto;
    margin-top: 30px;
    width: 100%;
}

.ant-popconfirm .ant-popover-message,
.ant-popconfirm .ant-popover-buttons {
    font-size: 1em;
}

div.ant-drawer {
    max-width: 500px;
    margin: 0 auto;
    position: absolute;
}

.usercode-count {
    text-align: right;
}

.timed-pin-codes-drawer .ant-drawer-body {
    scroll-behavior: smooth;
}

span.ant-radio-disabled+span {
    color: black;
}

.ant-popconfirm div.ant-popover-buttons,
.ant-modal div.ant-modal-confirm-btns {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    /* height: 50px; */
}

.ant-popover button,
.ant-popconfirm div.ant-popover-buttons button,
.ant-modal div.ant-modal-confirm-btns button {
    height: 100%;
    width: 70px;
}


body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div.ant-alert {
    margin: 8px 0;
}

div.ant-form-item-control-input {
    min-height: unset;
}

/*               */
/* LOCKER HEADER */
/*               */
.locker {
    display: flex;
    width: 100%;
    align-items: stretch;
}

.locker p {
    margin: 0;
}

.lockerImage {
    margin-right: 20px;
    width: 60px;
    align-self: flex-start;
}

.lockerInfo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.lockerName {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.lockerName>*:nth-child(1) {
    flex-grow: 1;
}

.lockerName>*:nth-child(2) {
    margin-left: 20px;
}

p.lockerId {
    font-size: .6rem;
    line-height: 1.5715;
    color: #888;
}

.locker textarea {
    resize: none;
    /* border: 1px solid deepskyblue; */
    padding: 2px 4px !important;
    line-height: 1.5715;
    border-radius: 0;
}

.locker textarea:hover,
.locker textarea:focus {
    border: 1px solid deepskyblue !important;
}

.openConfig,
.closeConfig {
    font-size: 1.8rem;
    margin: -5px;
    padding: 5px;
}

.lockerDescription {
    height: 100%;
    resize: none;
}

.drawer-header-center div.ant-drawer-header-title::after {
    content: "";
    width: 38px;
}

.ant-collapse div.collapseHideHeader > .ant-collapse-header {
    display: none;
}

.ant-collapse div.collapseHideHeader> .ant-collapse-content {
    border-top: 0;
}
.ant-collapse div.collapseHideHeader >.ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
}

.ant-checkbox-wrapper .ant-checkbox{
    align-self: unset;
    top: .2rem;
}

.sortDrawer div[class=ant-drawer-header-title] {
    flex-direction: row-reverse;
}

.sortDrawer div[class=ant-drawer-header-title] button {
    margin: -10px -10px -10px 12px
}

.sortDrawer li[class=ant-list-item] {
    display: block;
    padding: 0;
}