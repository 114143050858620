.wrapper{
    display: block;   
    min-height: 100%; 
}
.wrapper .back{
    color: black;
    margin: -10px;
    padding: 10px;
}
.header {
    margin-bottom: 15px;
    line-height: 50.288px;
}

.form{
    display: flex;
    flex-direction: column;
}
form.form >*{
    margin-bottom: 10px;

}

.forgotPassword{
    color: #00b2ff;
    text-decoration: none;
}
.forgotPassword:hover{
    color: #0094d3;
}
.forgotPassword:active{
    color: #007db3;
}