div.wrapper {
    padding: 0 .8rem;
    overflow-y: auto;
    height: 100%;
}

.header {
    font-size: 3rem;
    margin: 0;
}

button.addCodeButton {
    height: 32px;
    padding: 0;
    border-radius: 3px;
    border: 2px dashed #bbb;
}

.customLockerSettings div[class~=ant-collapse-content-box] :last-child {
    margin-bottom: 0;
}

.configNow *[class="ant-modal-confirm-btns"] {
    margin: 0;
}

.vertical div[class~=ant-form-item-control] {
    flex: 0 0 100%;
}

.userCode {
    margin: 0;
}