div.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 .8rem;
}

.wrapper p {
    margin: 0;
}

.drawer div[class~=ant-drawer-content-wrapper]{
    transition: all 0s !important;
}
.drawer input,
.drawer input:focus,
.drawer input:hover {
    margin-left: -11px;
    font-weight: 600;
    padding: 6px 13px;
    background: #f5f5f5;
    border-radius: 10px;
    height: 100%;
    width: calc(100% + 7px);
}

.drawer .options {
    display: flex;
    font-size: 24px;
    overflow: hidden;
    transition: .3s;
}

.drawer .options span {
    padding: 5px;
}

.drawer *[class=ant-drawer-header-heading-title] {
    flex: 1;
    overflow: visible;
}

.searchResults {
    flex-grow: 1;
    overflow: auto;
    overflow-x: hidden;
    padding: 0 .8rem;
}

.listItem {
    padding: 6px 0 !important;
    /* border: none !important; */
}

.listItem ul[class~=ant-list-item-action] {
    margin: 0;
}

.listItem ul[class~=ant-list-item-action] li {
    padding: 0;
}

.listItem div[class~=ant-list-item-meta-description],
.listItem h4[class~=ant-list-item-meta-title] {
    color: rgba(0, 0, 0, 0.85) !important;
    font-weight: normal !important;
}

.deleteButton {
    margin: -5px;
    padding: 5px;
    width: 40px;
    font-size: 1.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease-out;
    transform: scale(0);
    color: black;
    cursor: pointer;
}

.deleteButton.show {
    transition: .3s cubic-bezier(0.7, 0, 0.3, 1.5);
    transform: scale(1);
}

.loadingContainer {
    height: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
}

.loading h3 {
    margin-bottom: 20px;
}

.noLockersFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px .8rem;
}

.noLockersFound .addDevice {
    font-size: 3rem;
    color: #00B2FF;
}