.drawer .searchInput {
    border: none;
    outline: none;
    font-weight: 600;
    width: 100%;
}
.drawer *[class=ant-drawer-title]{
    flex: 1;
}

.options{
    font-size: 24px;
    display: flex;
}
.options span{
    padding: 5px;
}