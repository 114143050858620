/* Ant design */
.ant-page-header-heading-left,
.ant-page-header-heading-extra {
    margin: 0;
}

.ant-space-vertical {
    width: 100%;
}

 
/* RADIO */

.ant-radio-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

label.ant-radio-wrapper::after {
    display: none;
}

.ant-page-header {
    border-bottom: 1px solid #f0f0f0;
    font-weight: 500;
    margin: 0 -.8rem;
}

.ant-page-header-back-button,
.ant-drawer-close {
    margin: -10px;
    padding: 10px !important;
}

.ant-drawer-close {
    margin-right: 6px;
}

.header input {
    border: none;
    outline: none;
    font-weight: 600;
    width: 100%;
}

.ant-form-horizontal .ant-form-item-label {
    padding: 0 !important;
}
.ant-form-item{
    margin-bottom: 15px;
}

.ant-input.ant-input-sm{
    padding: 2px 10px;
    border-radius: 2px;
}

.ant-input-sm,
.ant-textArea,
.ant-picker,
div.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper,
.ant-input-password-middle.ant-input-affix-wrapper,
.ant-input-password-small.ant-input-affix-wrapper {
    border: 1px solid #bbb;
}

.ant-input.ant-input-lg,
.ant-input-affix-wrapper.ant-input-password-large {
    border-width: 2px !important;
    border-color: black;
    border-radius: 1px;
    outline: none;
    padding: 10px;
    line-height: 28.287px;
    font-size: 18px;
    box-shadow: none;
}

.ant-picker-small {
    padding: 2px 10px;
    border: 1px solid #bbb;

}

.ant-collapse.ant-collapse-ghost {
    border: 0;
}

.ant-collapse.ant-collapse-ghost>.ant-collapse-item {
    border-bottom: 0;
}

.ant-collapse {
    border-color: #ddd;
}

.ant-collapse-content {
    border-color: #ddd;
}

.ant-collapse>.ant-collapse-item {
    border-color: #ddd;
}
button.ant-btn{
    font-size: 15px;
}
.ant-btn.ant-btn-lg{
    border-width: 2px;
    line-height: 1.5715;
    padding: 10.9px 15px;
}

button.ant-btn-lg{
    min-height: 52px;
    height: unset;
}

.ant-btn:disabled {
    border-color: #666;
}
