.bannerContainer{
    transition: .3s;
    flex: content;
    height: auto;
    overflow-y: hidden;
}
.bannerContainer.hidden{
    flex: 0;
}

div.banner {
    margin: 0;
}
