.options {
    font-size: 24px;
    display: flex;
}

.options span {
    padding: 5px;
}

div.wrapper{
    height: 100%;
    font-size: 1rem;
    padding: 0 .8rem;
    overflow: auto;
}

.wrapper span[class~=anticon]{
    font-size: 1.3rem;
}
.wrapper table span[class~=anticon]{
    margin: -5px;
    padding: 5px;
}
.container{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.removeCode, .share, .space, .addCode{
    width: 14%;
    text-align: center;
}
.removeCode span{
    transition: .3s cubic-bezier(0.71, 0.01, 0.27, 1.55);
    transform: scale(0);
}
.removeCode.show span{
    transform: scale(1);
}
span.addCode{
    height: 37.14px;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.code, .name, .addCodeForm>*:nth-child(2), .addCodeForm>*:nth-child(3) {
    width: 36%;
}
.formContainer{
    overflow: hidden;
    position: sticky;
    bottom: 0px;
}
.formContainer.show{
    overflow: visible; 
}

form.addCodeForm{
    border-top: 1px solid #eee;
    margin: 0 -.8rem;
    padding: 0 .8rem;
    background: white;
    display: flex;
    transition: .1s;
}

.addCodeForm > *:nth-child(2), 
.addCodeForm > *:nth-child(3){
    margin: .5rem !important;
    font-size: .9rem;
    
}
.addCodeForm > *:nth-child(2) *, 
.addCodeForm > *:nth-child(3) *{
    width: 100%;
}
input.inputCode, 
input.inputName,
input.inputCode:hover, 
input.inputCode:focus, 
input.inputName:hover, 
input.inputName:focus{
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid deepskyblue !important;
}

.inputCode::-webkit-outer-spin-button,
.inputCode::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.duplicateModal div[class=ant-modal-confirm-btns],
.maxCodeModal div[class=ant-modal-confirm-btns]{
    height: 40px;
}

.editContainer{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editContainer .edit{
    padding: 10px 20px;
    background: #00B2FF;
    color: white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 1.3rem;
}

.editContainer .edit *{
    margin: 0;
}

.noCodesFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px .8rem;
}

div.noCodesFound .addCode {
    font-size: 3rem;
    color: #00B2FF;
}