.wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

div.wrapper button[class~="ant-btn-lg"] {
    font-size: 18px;
}

.optionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 8px;
}

.optionsContainer> :last-child {
    /* element after instuctions */
    z-index: 1;
    padding: 8px 0 0;
    background: white;
    margin: 0 0 -1px;
    width: 100%;
}

.optionsContainer> :last-child.blinkOptions {
    text-align: center;
    padding: 10px;
    width: calc(100% - 16px * 2);
    border-radius: 5px;
    margin-bottom: 16px;
}

/* Make sure 'loading' and 'disable' buttons are the same color */
.blinkOptions button[class~=ant-btn]::before {
    background: unset;
}

.instructions {
    flex: 1;
    z-index: 1;
    font-size: large;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.selectConfigButtons {
    display: flex;
    gap: 8px;
}

.selectConfigButtons> :last-child {
    flex-grow: 1;
}

.canvasContainer {
    width: 100%;
    flex-grow: 1;
    position: relative;
}

.canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.countdown {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #4d4d4d1f;
    z-index: 5;
}

.countdown p {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    line-height: 100px;
    text-align: center;
    vertical-align: middle;
    background: #090909;
    color: white;
    font-size: 3rem;
}

.instructions p {
    color: white;
}

.video {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -10;
}