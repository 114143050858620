.wrapper{
    height: 100%;
    overflow-y: auto;
}
.wrapper .back{
    color: black;
    margin: -10px -10px 0 -10px;
    padding: 10px;
}
.header {
    margin-bottom: 15px;
    line-height: 50.288px;
}