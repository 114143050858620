.wrapper{
    overflow: auto;
    height: 100%;
}
.wrapper * {
    user-select: none;
}
.wrapper p{
    margin: 0;
}

.activationCodesDrawer{
    left: 0;
    right: 0;
}