.wrapper{
    display: flex;
    min-height: 100%; 
    flex-direction: column;
    align-items: center;
    justify-content: space-between;    
}

.logo{
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center
}
.logo img:first-child {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
}
.logo img:last-child {
    width: 40%;
    margin: 0 auto;
}

.buttons{
    height: fit-content;
    display: flex;
    width: 100%;
    align-items: stretch;
    gap: 1vh;
    justify-content: space-evenly;
}

.buttons button{
    width: 48%;
}