.label {
    font-weight: bolder;
}
.wrapper p{
    margin: 0;
}
.locker{
    display: flex;
    margin: 0 .8rem;
    align-items: stretch;
}
.lockerImage{
    margin-right: 20px;
    width: 60px;
    height: 97px;
}
.lockerInfo{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.lockerName{
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.lockerName div:first-child {
    font-size: .9rem;
    
}
.lockerName div:first-child p:last-child {
    font-size: .8rem;
    color: #888;
    
}
p.lockerDescription {
    margin-top: 15px;
}
.configs > *{
    margin: .8rem;
}


.codeGeneration{
    display: flex;
    flex-direction: column;
    margin: 40px auto 0;
    align-items: center;
    width: 80%;
    
}
.generateCode{
    font-size: 4rem;
}
.code{
    width: 100%;
    display: flex;
    align-items: center;
}
.code::before{
    content: "";
    width: 20px;
}
p.generatedCode{
    margin: 10px auto 0;
    width: fit-content;
    font-size: 1.2rem;
    background: #eee;
    padding: 0px 6px;
    text-shadow: 0px 2px 3px #888;
}

.share{
    width: 20px;
    font-size: 1.2rem;
}
.dates table{
    font-size: 16px;
}
.validFor{
    margin-top: 15px;
}

.dates .label{
    padding-right: 20px;
}

div.oneTimeCodesList{
    margin: 10px auto 0;
    font-size: 1rem;
    width: calc(60% + 120px);
    max-width: 100%;
}
div.oneTimeCodesList div[class="ant-collapse-content-box"]{
    padding-top: 0 !important;
}
.oneTimeCode{
    padding: 10px 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.oneTimeCode:nth-child(odd){
    background: #f6f6f6;
}