.wrapper {
    max-width: 100%;
}

.form {
    flex-grow: 1;
    max-width: 100%;
}

.codesLink label, .codesLink label a{
    width: 100%;
}
