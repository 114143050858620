.nav{
    background: white; 
    width: 100%;
    height: 60px;
    border-top: 1px solid #bbb;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    position: relative;
}
.nav .link{
    color: black;
    text-decoration: none;
    line-height: 15px;
    width: 33.33%;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.nav .icon{
    line-height: 35px;
}
.nav .icon span{
    font-size: 1.3rem;
}
.nav .text{
    font-size: .6rem;
    font-weight: 700;
}
.nav > *:nth-child(2) .icon{
    background: #00B2FF;
    color: white;
    width: 70px;
    border-radius: 70px;
}
.nav > *:nth-child(2) .text{
    color: #00B2FF;
}
.nav *:nth-child(odd) .text{
    margin-top: -.5rem;
}